<template>
  <v-container>
    <v-card max-width="547" class="mx-auto pt-6 px-3 px-md-11 pb-8">
      <div class="text-center">
        <v-icon size="94" class="mb-2">$successPhase</v-icon>
      </div>

      <h1 class="font-medium-20 gray12--text text-center mb-1">
        {{ $t("panel.businessesPage.createWebGatewayReport.wellDone") }}
      </h1>

      <p class="gray10--text text-center mb-8 text-pre-line">
        {{
          $t("panel.businessesPage.createWebGatewayReport.webGatewayCreated")
        }}
      </p>

      <div class="pb-3 mb-8">
        <div class="font-medium-14 gray12--text mb-6">
          {{
            $t("panel.businessesPage.createWebGatewayReport.useRiverPayWays")
          }}
        </div>

        <div class="d-flex justify-space-between mb-6">
          <a
            :href="`${baseUrl}/developers`"
            target="_blank"
            class="text-decoration-none"
          >
            <v-icon size="24" class="me-2">$browser</v-icon>

            <span class="gray11--text">
              {{
                $t("panel.businessesPage.createWebGatewayReport.apiAndDocPage")
              }}
            </span>
          </a>

          <a :href="`${baseUrl}/developers`" target="_blank">
            <v-icon size="24">$shareArrow</v-icon>
          </a>
        </div>

        <v-divider></v-divider>

        <div class="d-flex justify-space-between mt-6">
          <a
            :href="`${baseUrl}/plugins`"
            target="_blank"
            class="text-decoration-none"
          >
            <v-icon size="24" class="me-2">$pluginSquare</v-icon>

            <span class="gray11--text">
              {{
                $t(
                  "panel.businessesPage.createWebGatewayReport.downloadPlugins"
                )
              }}
            </span>
          </a>

          <a :href="`${baseUrl}/plugins`" target="_blank">
            <v-icon size="24">$shareArrow</v-icon>
          </a>
        </div>
      </div>

      <v-btn
        color="gray2"
        block
        link
        depressed
        height="50"
        @click="goToBusinesses"
      >
        <span class="font-semiBold-14 gray12--text">
          {{ $t("panel.businessesPage.createWebGatewayReport.goToBusinesses") }}
        </span>
      </v-btn>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "create-web-gateway-report",
  data() {
    return {
      baseUrl: process.env.VUE_APP_PUBLIC_BASE_URL,
    };
  },
  methods: {
    goToBusinesses() {
      this.$router.replace("/panel/businesses");
    },
  },
};
</script>

<style scoped></style>
